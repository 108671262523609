<template>
  <dialog-outline v-model="showDialog" :scrollable="true">
    <template v-slot:icon-name v-show="visibleDialogIcon">
      {{ dialogIcon }}
    </template>
    <template v-slot:default>
      {{ dialogTitle }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- context -->
    <template v-slot:context>
      <!-- record details banner, which represents a subject that current operation will be performed -->
      <!-- <record-banner :record="record"></record-banner> -->

      <!-- display record -->
      <form-record
        v-model="isValidInput"
        :record="record"
        :category="category"
      ></form-record>
    </template>

    <!--  Dialog Actions -->
    <!--    <template v-slot:actions>-->
    <!--      <base-button @click="onUserTaskAccept">{{ dialogOkCommandLabel }}</base-button>-->
    <!--    </template>-->

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCloseCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { recordIconMixin } from "@/mixins/shared/record/recordIconMixin";

export default {
  name: "RecordDetailsDialog",
  mixins: [dialogOutlineMixin, recordIconMixin],
  data() {
    return {
      isValidInput: true
    };
  },
  components: {
    FormRecord: () => import("@/components/record/FormRecord")
  },
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    record: undefined,

    /**
     * Associated Record's Category
     * @type {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[]}
     */
    category: undefined
  },
  computed: {
    dialogIcon() {
      return this.recordIcon(this.record);
    },
    dialogTitle() {
      return `${this.record?.recordType} Details`;
    }
  }
};
</script>

<!--RecordDetailsDialog-->
